<template>
  <el-dialog top="10vh" width="1000px" custom-class="video-device-component" title="拍照" :visible="show"
    @close="$emit('close')" :close-on-click-modal="false" append-to-body @opened="fn_dialog_opened"
    @closed="fn_dialog_closed">
    <div class="tool-panel" v-if="videoList.length > 1">
      <el-radio v-model="currentDevice" :label="device.deviceId" v-for="(device, idx) in videoList" :key="idx">{{
          device.label
      }}</el-radio>
    </div>
    <div class="dlg-content">
      <video class="camera-video" ref="video"></video>
    </div>
    <div slot="footer">
      <el-button type="primary" size="small" @click="fn_take_photo">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getVideoList } from './util.js'
export default {
  name: "Test",
  created() { },
  data() {
    return {
      currentDevice: "",
      videoWidth: 1800,
      videoHeight: 1350,
      videoList: []
    };
  },
  watch: {
    currentDevice(deviceId) {
      if (deviceId) {
        localStorage.setItem('deviceId', deviceId)
        this.fn_openVideo({ deviceId })
      }
    }
  },
  props: {
    show: Boolean
  },
  methods: {
    fn_dialog_closed() {
      if (window.stream) {
        this.$refs.video.srcObject = null;
        window.stream.getTracks().forEach(track => track.stop());
        window.stream = null
      }
    },
    //拍照
    fn_take_photo() {
      const img = this.captureImage()
      this.$emit('success', img)
      this.$emit('close')
    },
    captureImage() {
      if (!this.$refs.video) {
        this.$message.error('未找到视频控件.')
        throw '未找到视频控件.'
      }
      const canvas = document.createElement('canvas')
      canvas.setAttribute('width', this.videoWidth)
      canvas.setAttribute('height', this.videoHeight)
      const ctx = canvas.getContext('2d')
      ctx.drawImage(this.$refs.video, 0, 0, this.videoWidth, this.videoHeight)
      const imgURL = canvas.toDataURL("image/jpeg")
      return imgURL

    },
    async fn_dialog_opened() {
      try {
        const videoList = await getVideoList()
        this.videoList = videoList
        if (videoList.length > 0) {
          const deviceId = localStorage.getItem('deviceId')
          if (deviceId && (videoList.filter(it => it.deviceId == videoList) == 1)) {
            this.fn_openVideo({ deviceId })
          } else {
            this.fn_openVideo(videoList[0])
          }
        } else {
          this.$message.error('未找到视频设备')
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async fn_openVideo({ deviceId }) {
      const loading = this.$loading.service({
        lock: true,
        text: '正在开启摄像头',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        this.currentDevice = deviceId
        const MediaStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId, width: this.videoWidth, height: this.videoHeight } })
        window.stream = MediaStream
        this.$refs.video.srcObject = MediaStream
        setTimeout(()=>{
          this.$refs.video.play();
        },150)
        loading.close()
      } catch (error) {
        window.stream = null
        loading.close()
        console.log("error", error)
        this.$refs.video.srcObject = null
        this.$message.error("开启摄像头失败。");
      }
    }
  },
};
</script>

<style lang="scss">
.video-device-component {
  height: 80vh;
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px 20px;
  }

  .tool-panel {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
  }

  .dlg-content {
    overflow: hidden;
    flex: 1;

    .camera-video {
      border: 1px solid #e4e4e4;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

    }

  }
}
</style>