<template>
  <div class="img-upload-component" :class="[size]">
    <!-- {{ $props }} -->
    <div class="img-content">
      <div class="img-content-inner" v-if="value" :style="{ backgroundImage: 'url(' + value + ')' }"></div>
      <div v-else class="img-content-empty">
        <i class="el-icon el-icon-picture-outline"></i>
      </div>
      <div class="img-content-panel">
        <div class="tool-panel">
          <div class="button" v-if="value" @click="showViewer = true">
            <i class="el-icon el-icon-view"></i>
          </div>
          <template v-if="!disabled">
            <div class="button" @click="fn_open_image('folder')">
              <i class="el-icon el-icon-folder-opened"></i>
            </div>
            <div class="button" v-if="haveVideoDevice" @click="fn_open_image('camera')">
              <i class="el-icon el-icon-camera"></i>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="img-title">{{ title }}</div>
    <el-image-viewer v-if="showViewer" :on-close="() => showViewer = false" :url-list="[value]" />
    <img-copper :show="imgCropperShow" :imgURL="value" :action="action" @close="imgCropperShow = false"
      @success="img_url => { $emit('input', img_url) }">
    </img-copper>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import ImgCopper from './img-cropper.vue';
import { getVideoList } from './util.js'
export default {
  name: "img-upload",
  created() { },
  data() {
    return {
      imgCropperShow: false,
      showViewer: false,
      haveVideoDevice: false,
      action: 'folder',
    };
  },
  components: { ElImageViewer, ImgCopper },
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      default: 'small',
      validator(value) {
        return ['medium', 'small', 'mini'].indexOf(value) !== -1
      }
    }
  },
  created() {
    getVideoList().then(list =>
      this.haveVideoDevice = list.length > 0
    )
  },
  methods: {
    fn_open_image(action) {
      this.action = action
      this.imgCropperShow = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.img-upload-component {

  border: 1px solid #e4e7ed;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;

  .img-title {
    height: 30px;
    flex-shrink: 0;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    user-select: none;
  }

  .img-content {
    flex: 1;
    position: relative;

    &-inner {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &-empty {
      height: 100%;
      width: 100%;
      font-size: 72px;
      color: #aaa;
      border-bottom: 1px solid #e4e7ed;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-panel {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #aaaaaaaa;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: opacity 200ms;

      &:hover {
        opacity: 1;
      }

      .tool-panel {
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;


        .button {
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          margin-right: 4px;
          font-size: 22px;
          box-sizing: border-box;
          cursor: pointer;
          border-radius: 8px;
          background-color: #909399ba;

          &:hover {
            background-color: #448aff;
            color: white;
          }
        }
      }
    }
  }

  &.medium {
    width: 320px;
    height: 270px;
  }

  &.small {
    width: 280px;
    height: 240px;
  }

  &.mini {
    width: 240px;
    height: 210px;
  }

}
</style>