<template>
  <el-dialog top="10vh" width="1000px" custom-class="img-cropper-component" title="图片" :visible="show"
    @close="$emit('close')" @opened="fn_dialog_opened" @closed="option.img = null" :close-on-click-modal="false"
    append-to-body>
    <div class="img-cropper-wrapper">
      <vue-cropper ref="cropper" v-bind="option" @realTime="fn_cropper_real_time"></vue-cropper>
    </div>
    <div class="img-cropper-preview" ref="previewBox">
      <div class="img-preview-box" v-if="previews.url" :style="previews.div">
        <img :style="previews.img" :src="previews.url" alt="预览" />
      </div>
      <div v-else class="preview-empty">
        <i class="el-icon el-icon-picture"></i>
        <span>图片预览</span>
      </div>
    </div>
    <div slot="footer">
      <el-button type="danger" size="small" @click="loadLocalImage">载入图片</el-button>
      <el-button type="info" size="small" v-if="showCaptureButton" @click="fn_show_video">拍照</el-button>
      <el-button icon="el-icon-refresh-right" :disabled="!option.img" type="primary" size="small"
        @click="$refs.cropper.rotateRight()"></el-button>
      <el-button icon="el-icon-refresh-left" :disabled="!option.img" type="primary" size="small"
        @click="$refs.cropper.rotateLeft()"></el-button>
      <el-button type="primary" size="small" :disabled="!option.img" icon="el-icon-zoom-in"
        @click="$refs.cropper.changeScale(5)"> </el-button>
      <el-button type="primary" size="small" :disabled="!option.img" icon="el-icon-zoom-out"
        @click="$refs.cropper.changeScale(-5)"> </el-button>

      <el-button :disabled="!option.img" type="warning" size="small" @click="fn_cropper_save">保存</el-button>
    </div>
    <video-device-dialog :show="showVideoDialog" @success="imgBase64Data => option.img = imgBase64Data"
      @close="showVideoDialog = false" />
  </el-dialog>
</template>

<script>
import { Dialog } from "element-ui";
import { VueCropper } from 'vue-cropper'
import VideoDeviceDialog from "./VideoDeviceDialog.vue";
import { getLocalImgFile, getVideoList } from './util.js'
export default {
  name: "Test",
  created() { },
  data() {
    return {
      showCaptureButton: true,
      showVideoDialog: false,
      previews: {},
      // previewBoxHeight:100,
      option: {
        img: null,
        autoCrop: true,
        info: true,
        original: true,
      }
    };
  },
  props: {
    show: { type: Boolean, default: false },
    action: {
      type: String,
      default: 'folder',
      validator(value) {
        return ['camera', 'folder'].indexOf(value) !== -1
      }
    },
    imgURL: String
  },

  methods: {
    //保存
    fn_cropper_save() {
      // this.$refs.cropper.getCropData(data => {
      //   // do something
      //   console.log(data)
      // })

      this.$refs.cropper.getCropBlob(async multipartFile => { 
        const formData = new FormData()
        formData.append('multipartFile', multipartFile)
        const res = await this.$http("api1", "/api/biz/util/uploadFile", "post", formData)
        this.$emit('success', res.data.data.url)
        this.$emit('close')
      })
    },
    //实时预览
    fn_cropper_real_time(data) {
      this.previews = data
      const { width } = this.$refs.previewBox.getBoundingClientRect()
      // this.previewBoxHeight=data.h/data.w*width
      this.previews.div.transform = `scale(${width / data.w})`
    },
    async loadLocalImage() {
      // const loading = this.$loading.service({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      try {
        const file = await getLocalImgFile()
        this.option.img = URL.createObjectURL(file)
        // loading.close()
      } catch (error) {
        console.log('error', error)
        // loading.close()
      }

    },
    async fn_show_video() {
      try {
        const videoList = await getVideoList()
        if (Array.isArray(videoList) && videoList.length > 0) {
          this.showVideoDialog = true
        } else {
          this.$message.error('没找到视频设备')
        }
      } catch (error) {
        console.log("error", error)
      }
    },
    // drawImg(url) {
    //   const img = new Image()
    //   img.crossOrigin = "Anonymous";
    //   img.onload = function () {
    //     console.log(img)
    //     const w = img.width, h = img.height;
    //     const canvas = document.createElement('canvas')
    //     canvas.setAttribute('width', w)
    //     canvas.setAttribute('height', h)
    //     const ctx = canvas.getContext('2d')
    //     ctx.drawImage(img, 0, 0, w, h)
    //     const localImgUrl = canvas.toDataURL('image/jpeg')
    //   }
    //   img.src = url
    // },
    fn_dialog_opened() {
      try {
        if (this.imgURL) {
          this.option.img = this.imgURL
          // this.drawImg(this.imgURL)
          // const protocol = document.location.protocol
          // this.option.img = this.imgURL.replace(/http(s?):\/\//, protocol+"//")
          // console.log(this.option.img);
        }


        if (this.action == 'folder') {
          this.loadLocalImage()
        }
        if (this.action == 'camera') {
          this.fn_show_video()

        }
        getVideoList().then(res => {
          this.showCaptureButton = res.length > 0;
        })
      } catch (error) {
        console.log("error", error);
      }
    }
  },
  components: { ElDialog: Dialog, VueCropper, VideoDeviceDialog }
};
</script>

<style lang="scss">
.img-cropper-component {
  height: 80vh;
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    flex: 1;
    display: flex;
  }

  .img-cropper-wrapper {
    flex: 2
  }

  .img-cropper-preview {
    flex: 1;
    margin-left: 10px;
    background-color: #d8caca26;
    overflow: hidden;

    .img-preview-box {
      position: relative;
      height: 100px;
      overflow: hidden;
      transform-origin: left top;
      box-sizing: border-box;

      img {
        position: absolute;
        width: 100%;
      }
    }
  }

  .preview-empty {
    height: 40%;
    font-size: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:"#1044be";

    span {
      font-size: 18px;
      display: block;
    }
  }
}
</style>