export const getLocalImgFile = () =>
  new Promise((resolve, reject) => {
    let input_dom = document.createElement("input");
    if (document) {
      document.body.appendChild(input_dom);
    }
    input_dom.setAttribute("type", "file");
    input_dom.setAttribute("accept", "image/*");
    input_dom.style.display = "none";
    input_dom.addEventListener("change", () => {
      if (input_dom.files.length > 0) {
        resolve(input_dom.files[0]);
      } else {
        reject();
      }
      document.body.removeChild(input_dom);
    });

    //派发事件 。
    var my_event = document.createEvent("MouseEvents");
    my_event.initMouseEvent("click", true, true);
    input_dom.dispatchEvent(my_event);
  });

let videoDeviceList = [];
const _getVideoList = () => {
  if (location.protocol == "http:" && location.hostname != "localhost") {
    return [];
  }

  return navigator.mediaDevices
    .enumerateDevices()
    .then((list) => list.filter((it) => it.kind == "videoinput"));
};

export async function getVideoList() {
  if (videoDeviceList.length == 0) {
    videoDeviceList = await _getVideoList();
  }
  return videoDeviceList;
}
// getVideoList().then(console.log);
